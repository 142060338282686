import * as React from "react";
import styled from "styled-components";
import { theme } from "./colors";

const FooterSection = styled.div`
  padding: 1rem;
  width: 100%;
  text-align: center;
  font-size: 1rem;
  color: ${theme.text};
`;

const Footer = () => {
  return <FooterSection> created by Łukasz Stasiak 2021</FooterSection>;
};

export default Footer;
