import * as React from "react";
import styled from "styled-components";
import { ImgLink, ItemDescription, ItemLink, ItemLinkWrapper } from "./common";

const ItemProjectsSpace = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 2rem;
  background-color: #2d3951;
  padding:  0.8rem;
  border-radius: 0.2rem;
	@media (max-width: 770px) {
    flex-direction: column;
  }
`;
const ItemImage = styled.img`
  width: 100%;
  display: flex;
  padding-right: 0.6rem;
  height: 100%;
	@media (max-width: 770px) {
    padding-right: 0;
  }
`;

const ItemProjectsContent = styled.div`
  padding: 0 0.6rem;
  width: 50%;
  display: flex;
  flex-direction: column;
	@media (max-width: 770px) {
    width:100%;
  }
`;

const ItemTitle = styled.div`
  font-size: 1.4rem;
  text-align: center;
  width: 100%;
  margin-bottom: 2rem;
`;

export const ItemTags = styled.div`
  font-size: 1.2rem;
  width: 100%;
`;

export const getTags = (tags) => {
	const tagsArray =[]
	tags.map(tag => tagsArray.push(tag.name))
	return tagsArray.join(', ');
}

const ItemProjects = ({ title, desc, coverImg, tags, link }) => {
	return (
    <ItemProjectsSpace>
      <ImgLink half to={link}>
        <ItemImage src={coverImg} />
      </ImgLink>
      <ItemProjectsContent>
        <ItemTitle>{title}</ItemTitle>
        <ItemTags>Technology: {getTags(tags)}</ItemTags>
        <ItemDescription more>
          <div dangerouslySetInnerHTML={{ __html: desc }} />
        </ItemDescription>
        <ItemLinkWrapper>
          <ItemLink to={link}>Read more</ItemLink>
        </ItemLinkWrapper>
      </ItemProjectsContent>
    </ItemProjectsSpace>
  );
};

export default ItemProjects;
