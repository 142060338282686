import * as React from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import lf_logo_file from "../../assets/logo/lf_logo_white.svg";
import { theme } from "../utilis/colors";

const TopBar = styled.div`
  padding: 1rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${(p) => (p.alone ? "rgba(0,0,0,0.8)" : "transparent")};
	`;
const TopBarColor = styled.div`
  width: 100%;
	background-color: ${(p) => (p.alone ? theme.mainBackground : "transparent")};
`;
const LogoLink = styled(Link)`
  display: flex;
  text-decoration: none;
`;
const Logo = styled.img`
  display: flex;
  width: 100%;
  max-width: 7rem;
	@media (max-width: 400px) {
    max-width:5rem;
  }
`;
const MenuBar = styled.div`
  display: flex;
  align-items: center;
`;
const MenuLink = styled(Link)`
  color: ${theme.text};
  font-size: 1.2rem;
  text-decoration: none;
  padding: 0.4rem 0.8rem;
  font-weight: 300;
  border-radius: 0.2rem;
  transition: 300ms;
  :hover {
    background-color: rgba(255, 255, 255, 0.1);
    transition: 300ms;
  }
`;

const Menu = ({ alone, color }) => {
  return (
    <TopBarColor alone={alone}>
      <TopBar alone={alone}>
        <LogoLink to="/">
          <Logo src={lf_logo_file} />
        </LogoLink>
        <MenuBar>
          <MenuLink to="/">Home</MenuLink>
          <MenuLink to="/projects">Projects</MenuLink>
          <MenuLink to="/blog">Blog</MenuLink>
        </MenuBar>
      </TopBar>
    </TopBarColor>
  );
};

export default Menu;
