import * as React from "react";
import { graphql } from "gatsby";
import Menu from "../components/header/Menu";
import Projects from "../components/projects/Projects";
import Layout from "../components/utilis/Layout";
import { Main } from "../components/utilis/common";
import Footer from "../components/utilis/Footer";

export const query = graphql`
  query {
		allWpPost(
			sort: {order: DESC, fields: [date]}
			filter: {categories: {nodes: {elemMatch: {name: {eq: "Projects"}}}}}
		) {
			edges {
				node {
					title
					id
					slug
					date(formatString: "DD-MM-YYYY")
					excerpt
					featuredImage {
						node {
							sourceUrl
						}
					}
					tags {
						nodes {
							name
						}
					}
					categories {
						nodes {
							name
						}
					}
				}
			}
		}
	}
	
`;


const ProjectsPage = ({data}) => {
	const {allWpPost} = data;
  return (
    <Layout>
    <Main>
      <title>My Projects</title>
      <Menu alone/>
      <Projects projects={allWpPost}/>
      <Footer/>
    </Main>
    </Layout>

  );
};

export default ProjectsPage;
