import { Link } from "gatsby";
import styled from "styled-components";
import { theme } from "./colors";

export const ItemsArea = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
`;

export const MainText = styled.div`
  padding-bottom: 1.2rem;
  font-size: 1.4rem;
	@media (max-width: 400px) {
    font-size: 1.2rem;
  }
`;

export const ItemLinkWrapper = styled.div`
  display: flex;
  width: 100%;
  padding: 0.8rem 0;
  align-items: center;
  justify-content: center;
`;

export const ItemLink = styled(Link)`
  display: flex;
  padding: 0.4rem 0.8rem;
  color: ${theme.action};
  text-decoration: none;
  font-size: 1.2rem;
  text-align: center;
  border-radius: 0.2rem;
  transition: 300ms;
  :hover {
    background-color: rgba(255, 255, 255, 0.1);
    transition: 300ms;
  }
`;
export const ImgLink = styled(Link)`
  width: ${(p) => (p.half ? "50%" : "100%")};
	@media (max-width: 770px) {
    width:100%;
		margin-bottom: 1.2rem;;
  }
`;

export const ItemDescription = styled.div`
  margin-top: 1.2rem;
  font-size: 1.2rem;
  width: 100%;
  display: -webkit-box;
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-line-clamp: ${(p) => (p.more ? 6 : 3)};
  -webkit-box-orient: vertical;
  p {
    padding: 0;
    margin: 0;
  }
`;

export const Main = styled.main`
  padding: 0;
  margin: 0;
`;
