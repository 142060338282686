import * as React from "react";
import Section from "../utilis/Section";

import { ItemsArea } from "../utilis/common";
import ItemProjects from "../utilis/ItemProjects";
import { theme } from "../utilis/colors";

const Projects = ({ projects }) => {
  return (
    <Section bgColor={theme.mainBackground} textColor={theme.text} title="My Projects">
      <ItemsArea>
        {projects.edges.map((item) => (
          <ItemProjects
            title={item.node.title}
            desc={item.node.excerpt}
            key={item.node.id}
            coverImg={item.node.featuredImage.node.sourceUrl}
            date={item.node.date}
            category={item.node.categories.nodes[0].name}
            link={item.node.slug}
						tags={item.node.tags.nodes}
          />
        ))}
      </ItemsArea>
    </Section>
  );
};

export default Projects;
